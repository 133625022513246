// HeartLoader.js
import React from 'react';
import './heart-loader.css';

const HeartLoader = ({ size, isFullScreen }) => (
  <div className={`wrap-heart-loader ${isFullScreen && 'full-screen'}`}>
    <svg
      width={size || '40%'}
      id="e80nr3tfyz5r1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 100" // Adjust the viewBox to give more space
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <defs>
        <linearGradient
          id="e80nr3tfyz5r2-stroke"
          x1="0.160971"
          y1="0.156028"
          x2="0.688065"
          y2="0.723610"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0%" stopColor="rgb(45,200,174)" />
          <stop offset="100%" stopColor="rgb(56,142,217)" />
        </linearGradient>
      </defs>
      <path
        id="e80nr3tfyz5r2"
        d="M45.496410,17.311583C31.019625,-25.085385,-48.085660,19.896764,45.496410,81.424072C139.078479,19.896764,59.973194,-24.568349,45.496410,17.311583Z"
        transform="translate(10, 10)" // Move the heart to center it within the SVG
        fill="none"
        stroke="url(#e80nr3tfyz5r2-stroke)"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="22.926"
        strokeDashoffset="282.82"
        strokeDasharray="282.82"
        className="heart-path"
      />
    </svg>
  </div>
);

export default HeartLoader;
