import { Modal } from "reactstrap"
import './modals.scss'
import { t } from "multi-language/multi-lang.service"

export default function EmptyReportModal({routeBack}) {
  
  return (
    <Modal
      id="blank-report-modal"
      className="empty-report-modal modal-dialog-centered"
      backdropClassName="empty-report-modal-bg"
      size="md"
      isOpen={true}
    >
      <div className="modal-header d-block text-center">
        <span className="text-center">{t('modals.blank_report.title')}</span>
      </div>
      <div className="modal-body">
        <span>{t('modals.blank_report.text')}</span>
      </div>
      <div className="modal-footer">
        <a className="btn btn-primary" href={routeBack}>{t('modals.blank_report.back')}</a>
      </div>
    </Modal>
  )
}
