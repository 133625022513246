import React, { useEffect } from 'react'
import { Row, Col } from "reactstrap"
import './realtime-Measure-box.css'
import { useSelector } from 'react-redux'
import { t } from 'multi-language/multi-lang.service'
import { isConfigWatch } from 'utils'

export default function RealtimeMeasureBox({ rtMeasurements, patient, setAlert, alert, configurations }) {
    const patientMeasurments = useSelector(state => state?.socketPatientsData?.[patient.id])
    useEffect(() => {
        if (patientMeasurments?.message) {
            setAlert(patientMeasurments.message)
        } else {
            setAlert()
        }
    }, [patientMeasurments])

    // if(!configurations) return null
    const realtimeMeasurements = [
        {
            key: 'heart_rate',
            title: t('measurements.heart_rate.title'),
            value: rtMeasurements?.heart_rate?.value,
            unit: ''
        },
        {
            key: 'spo2',
            title: t('measurements.spo2.title'),
            value: rtMeasurements?.spo2?.value,
            unit: '%'
        }
    ]

    const showBox = (measurement) => {
        if(!shouldDisplayMeasuremtnt(measurement)) return null
        return (
            
            <div className='realtime-box'>
                <Col>
                    <Col className='box-value'>
                        <Row>
                            {measurement.title}
                        </Row>
                        <Row id='rt-measure-val'>
                            {`${measurement?.value || '---'} ${measurement.unit}`}
                        </Row>
                        <Row>
                            <div className='realtime-line' />
                        </Row>
                    </Col>

                </Col>

            </div>
        )
    }

    const shouldDisplayMeasuremtnt = (measurement) => {
        return isConfigWatch(configurations?.configuration?.measurements?.[measurement.key])
    }

    return (

        <Col className='measure-box-container'>
            {
                realtimeMeasurements.map((measurement) =>
                    showBox(measurement))
            }
            <Row id='rt-alert-text' className='alert'>{alert}</Row>
        </Col>
    )


}
