/* eslint-disable react-hooks/exhaustive-deps */
import { USER_SPECIAL_FIELDS } from 'consts'
import React, { useEffect, useState } from 'react'
import ChangeUserPassword from './change-user-password'
import { deleteUser, getCountriesAndConfig, sendResetPasswordMail, unlinkWatch, unlockUser, updatePatientOrDocAndUser } from 'api/admin-api'
import { useSelector } from 'react-redux'
import Physicians from 'pages/physicians/Physicians'

function PatientEdit({data}) {

    const { countries, user: adminUser } = useSelector(state => state)
    const [isEdit, setIsEdit] = useState(false)
    const [isPhysicianEdit, setIsPhysicianEdit] = useState(false)
    const [newData, setNewData] = useState(data)

    const fetchData = async () => {
        if(!countries.length) await getCountriesAndConfig()
    }
    
    useEffect(() => {
        fetchData()
    }, [])
    
    useEffect(() => {
        setNewData(data)
    }, [data])

    const handleInput = (e, type) => {
        setNewData(prevData => ({...prevData, [type]:{...prevData[type], [e.target.name]: e.target.value}}))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log('newData', newData)
        await updatePatientOrDocAndUser(newData)
        setIsEdit(false)
    }

    const cancelEdit = () => {
        setIsEdit(false)
        setNewData(data)
    }

    const getCountriesOptions = () => {
        return countries.map(op => ({value: op.code, label: op.name}))
    }

    const getOrderedOptions = (options) => {
        return options.map(op => ({value: op?.value || `${op}`.toLowerCase(), label: op?.label || op}))
    }

    const renderSelectField = (type, field) => {
        let {options} = USER_SPECIAL_FIELDS[field]
        options = field === 'country' ? getCountriesOptions() : getOrderedOptions(options)
        return (
            <div key={field} className='form-group'>
                <label htmlFor={field}>{USER_SPECIAL_FIELDS[field]?.title || field}: </label>
                <select disabled={!isEdit || USER_SPECIAL_FIELDS[field]?.blockEdit} name={field} value={newData?.[type]?.[field] || ''} onInput={(e) => handleInput(e, type)} className='form-control' id={field} >
                    {options.map(op => <option key={op.value} value={op.value}>{op.label}</option>)}
                </select>  
            </div>
        )

    }

    const renderField = (type, field) => {
        if(USER_SPECIAL_FIELDS[field]?.options){
            return renderSelectField(type, field)
        }
        return (
            <div key={field} className='form-group'>
                <label htmlFor={field}>{USER_SPECIAL_FIELDS[field]?.title || field}: </label>
                <input readOnly={!isEdit || USER_SPECIAL_FIELDS[field]?.blockEdit} name={field} value={newData?.[type]?.[field] || ''} onInput={(e) => handleInput(e, type)} className='form-control' id={field} />  
            </div>
        )

    }

    const handleDeleteUser = () => {
        const toDelete = window.confirm('are you shure you want to delete this pateint?')
        if(toDelete) deleteUser(data?.user?.id)
    }

    const handleUnlock = async () => {
        await unlockUser(data?.user?.id, true)
    }

  if(isPhysicianEdit){
    return (
        <>
            <button onClick={() => setIsPhysicianEdit()} className='btn btn-outline-dark mr-2'>
               {'<- Back to patient'}
            </button>
            <Physicians patientId={data?.patient?.id} jwtToken={adminUser?.jwtToken} role={adminUser?.type} />
        </>
    )
  }

  return (
    <div className=''>
            <div className='patient-actions row mb-3'>
                <button onClick={() => handleDeleteUser()} className='btn btn-danger mr-2'>
                    Delete user
                </button>
                <button onClick={() => setIsEdit(!isEdit)} className='btn btn-success mr-2'>
                    Edit
                </button>
                <button onClick={handleUnlock} className='btn btn-success mr-2'>
                    Unlock user
                </button>
                {data?.patient && 
                    <>
                        <button disabled={!data?.patient?.deviceId} onClick={() => unlinkWatch(data?.patient?.id, true)} className='btn btn-success mr-2'>
                            Unlink watch
                        </button>
                        <button onClick={() => setIsPhysicianEdit(true)} className='btn btn-success mr-2'>
                            Set physicians
                        </button>
                    </>
                }
                <button onClick={() => sendResetPasswordMail(data?.user?.email, true)} className='btn btn-success mr-2'>
                    Send reset password mail
                </button>
               <ChangeUserPassword userId={data?.user?.id}/>
            </div>

            <form onSubmit={handleSubmit} className='form w-50'>
                {
                    Object.keys(newData).map(type => 
                        newData[type] && <div key={type}>
                            <h2>{type}</h2>
                            {Object.keys(newData[type])?.map(field => renderField(type, field))}
                        </div>
                    )
                }
                {isEdit && 
                    <div className='edit-patient-form-actions'>
                        <button disabled={false} type='submit' className='btn btn-primary'>Save Patient</button>
                        <button onClick={cancelEdit} type='button' className='btn btn-danger'>Cancel</button>
                    </div>
                }
            </form>        
    </div>
  )
}

export default PatientEdit