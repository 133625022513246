import { getCountriesAndConfig, sendMessageToUsers } from 'api/admin-api'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactSelect from 'react-select'

const msgInputs = {
  title: { type: 'text', value: '' },
  text: { type: 'text', value: '' },
  sendNotification: { type: 'checkbox', value: false },
  sendEmail: { type: 'checkbox', value: false },
  sendTo: { type: 'select', value: 'patientId' },
  patientId: { type: 'number', value: '' },
  country: { type: 'select', value: '' },
  config: { type: 'select', value: '' },
  platform: { type: 'select', value: '' },
}

function MessagesComponent() {

  const { countries, appConfigurations: configs } = useSelector(state => state)
  const [msg, setMsg] = useState(getInitValues())
  const [error, setError] = useState('')

  function getInitValues() {
    const initMsg = {}
    Object.keys(msgInputs).map(field => { initMsg[field] = msgInputs[field].value })
    return initMsg
  }

  const fetchData = async () => {
    if (!countries.length) await getCountriesAndConfig()
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleInput = (e) => {
    let value = e.target.value
    if (e.target.type === 'checkbox') {
      value = e.target.checked
    }
    setMsg(prevData => ({ ...prevData, [e.target.name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await sendMessageToUsers(msg)
      cancel()
    } catch (error) {
      console.log('error', error)
    }
  }

  const cancel = () => {
    setMsg(getInitValues())
  }

  const isDisabled = () => {
    if(!msg.title || !msg.text) return true
    if(!msg[msg.sendTo] && msg.sendTo !== 'all') return true
    if(!msg.sendEmail && !msg.sendNotification) return true
    return false
  }

  const getSelectOptions = (field) => {
    switch(field){
      case 'country':
        return countries?.map((c) => { return { value: c.code, label: c.name, id: c.id } })
      case 'config':
        return configs?.map((c) => { return { value: c.id, label: c.name, id: c.id } })
      case 'platform':
        return [
          { value: 'android', label: 'Android' },
          { value: 'ios', label: 'Ios' },
        ]
      case 'sendTo':
        return [
          { value: 'all', label: 'All users' },
          { value: 'patientId', label: 'Send to a patient' },
          { value: 'config', label: 'Send by configuration' },
          { value: 'country', label: 'Send by country' },
          { value: 'platform', label: 'Send by platform' }
        ]
      default: return []
    }
  }

  function renderSelect(field) {
    if (msg.sendTo !== field && field !== 'sendTo') return null
    const options = getSelectOptions(field)
    const selectedOption = options?.filter(op => op.value === msg[field])
    return (
      <div key={field} className='form-group'>
        <label htmlFor={field}>{field}: </label>
        <ReactSelect
          options={options}
          defaultValue={selectedOption}
          onChange={(selectedElement) => {
            setMsg({ ...msg, [field]: selectedElement?.value })
          }}
        />
      </div>
    )
  }

  const renderField = (field) => {
    if (msgInputs[field]?.type === 'select') {
      return renderSelect(field)
    }

    if (field === 'patientId' && msg.sendTo !== 'patientId') {
      return null
    }

    let inputProps = { type: msgInputs[field].type, name: field, value: msg?.[field], onInput: handleInput, className: 'form-control', id: field }
    let RenderInput = <input {...inputProps} />

    if (field === 'text') {
      RenderInput = <textarea cols={5} rows={5} {...inputProps}></textarea>
    }

    return (
      <div key={field} className='form-group wrap-msg-input'>
        <label htmlFor={field}>{field}: </label>
        {RenderInput}
      </div>
    )
  }

  return (
    <div className='wrap-messages-page'>
      <div className='card pt-5'>
        <div className='card-header'>
          <h4 className='card-title'>Send messages to users</h4>
          <form onSubmit={handleSubmit} className='messages-form form w-50'>
            {Object.keys(msg).map(field => renderField(field))}
            <div className='text-danger'>{error}</div>
            <div className='edit-patient-form-actions'>
              <button disabled={isDisabled()} type='submit' className='btn btn-primary'>Send message</button>
              <button onClick={cancel} type='button' className='btn btn-danger'>Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default MessagesComponent