
/*eslint-disable*/
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux'
import { PropTypes } from "prop-types";
import avatar from 'assets/img/icons/avatar.svg'
import {Collapse, Navbar, NavItem, Nav,Container} from "reactstrap";
import SaveChangesGuard from "components/Modals/SaveChangesGuard";
import { setUnsavedChanges } from 'store/actions'
import moment from "moment-timezone";
import { timeZoneConvert } from "utils";
import axios from "axios";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  const [saveGaurdModal, toggleSaveGaurdModal] = useState(false)
  const [saveGaurdLink, setSaveGaurdLink] = useState()
  const { routes, user, unsavedChanges,setUnsavedChanges } = props;
  const {physicians, patients}= useSelector((state) => state)
  const doctorStatus = physicians.filter((doctor)=> (doctor.status === 'removed-by-doctor'|| doctor.status === 'approved' || doctor.status === 'denied') && !doctor.status_checked)
  const nickname = user?.data?.nickname ? `(${user?.data?.nickname})` : ''
  const name = user?.type ==='doctor' ? user.data.prefix ? 
  `${user.data.prefix}. ${user?.data?.firstName} ${nickname} ${user?.data?.lastName}` 
  : user?.data?.firstName ? 
  `Dr. ${user?.data?.firstName} ${user?.data?.lastName}` 
  :`Dr. ${user.data.username}`
  : user?.data?.firstName ? `${user?.data?.firstName} ${user?.data?.lastName}` 
  :user.data.username
  //const entries = JSON.parse(localStorage.getItem('entries')) 
  const lastEntryItem = localStorage.getItem('lastEntry')
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const confName = props?.user?.data?.appConfigs?.name;
  const requestPatientsCount = patients.filter(p => (p.status === 'pending' || p.status === 'removed-by-patient') && !p.status_checked)
  const css = `
  .relative{
    position: relative;
  }
  .circle-count {
    border: solid 1px blue;
    position: absolute;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    color: white;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    left: 30%;
    top: 55%;
}`
  const lastEntry = moment(timeZoneConvert(lastEntryItem)).format('lll')
  
  const onLinkClick = (link) => {
    if(unsavedChanges){
        setSaveGaurdLink(link)
        toggleSaveGaurdModal(true)
    } else{
        navigate(link)
    }
  }

  const onGaurdLeave = ()=>{
    toggleSaveGaurdModal(false)
    setUnsavedChanges()
    navigate(saveGaurdLink)
  }

  const onGaurdConfirm = ()=>{
    unsavedChanges?.callback()
    navigate(saveGaurdLink)
    toggleSaveGaurdModal(false)
  }

  const circleRequestCount = (name) => {
    if((name === 'Patient Requests' && requestPatientsCount.length) || (name === 'Physicians' && doctorStatus.length)) {
      return <div className="circle-count">{requestPatientsCount.length||doctorStatus.length}</div>
    }
  }

  const createLinks = (routes) => {
    const location = useLocation()
    return routes.map((prop, key) => {
      const upperLayout = prop.layout.split('/')[1]
      const isActive = prop.path.split('/')[1]===location.pathname.split('/')[1]
      const href = (upperLayout===user.type ? '' : prop.layout) + prop.path

      return upperLayout===user.type && prop.name &&  (
        <NavItem key={key}>
          <div
            //activeClassName="activate"
            //tag={NavLinkRRD}
            style={{paddingLeft:'56px',margin:'0px 8px'}}
            onClick={()=>onLinkClick(href)}
            className={`relative nav-link ${isActive?'activate':''}`}> 
            {circleRequestCount(prop.name)}
            <img alt="icon" src={require(`../../assets/img/icons/${prop.name}.svg`)} style={{marginRight:'10px'}}/>
            {prop.name}
          </div>
        </NavItem>
      );
    });
  };

  return props.display? (
    <>
    <style scoped>{css}</style>
    <SaveChangesGuard 
      isOpen={saveGaurdModal} 
      toggleModal={toggleSaveGaurdModal}
      continueLink={saveGaurdLink}
      onLeave={onGaurdLeave} 
      onConfirm={onGaurdConfirm} />
    <Navbar
      className="navbar-vertical fixed-left navbar-dark bg-blue"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        <div style={{height:'60px'}}/>
        <Collapse navbar isOpen={collapseOpen}>
        <img alt="avatar" src={avatar} height="80px"/>
        <h3 className="text-white text-center mt-3" >Hello, {name}</h3>  
        <p className="text-white text-center" style={{fontSize:'12px'}} >Last entry: {lastEntry}</p>
        <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </Container>
    </Navbar>
    </>
  ) :'';
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = ({unsavedChanges}) => ({unsavedChanges})
const mapDispatchToProps = {setUnsavedChanges}
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)


/* 
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
 */

{/* Divider */}
{/* <hr className="my-3" /> */}
{/* Heading */}
{/* <h6 className="navbar-heading text-muted">Documentation</h6>*/}          
{/* Navigation */}
{/* <Nav className="mb-md-3" navbar>
  <NavItem>
    <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/overview?ref=adr-patient-sidebar">
      <i className="ni ni-spaceship" />
      Getting started
    </NavLink>
  </NavItem>
  <NavItem>
    <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/colors?ref=adr-patient-sidebar">
      <i className="ni ni-palette" />
      Foundation
    </NavLink>
  </NavItem>
  <NavItem>
    <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/alerts?ref=adr-patient-sidebar">
      <i className="ni ni-ui-04" />
      Components
    </NavLink>
  </NavItem>
</Nav> */}