import { Fragment, useRef, useState } from "react";
import { Container, Row, Col, Table, Card, CardHeader } from "reactstrap";
import SubNavbar from "components/Navbars/SubNavbar";
import Select from 'react-select'
import { DropdownIndicator } from 'components/Inputs/Select'
import TextTransition, { presets } from "react-text-transition";
import { ARRYTHMIA_EVENTS, ARRYTHMIA_EVENT_TYPES, DOCTOR_TABLE_COLUMNS as columns, dashboardKeys } from "consts";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import pinnedon from '../../assets/img/icons/pinnedon.svg'
import pinnedoff from '../../assets/img/icons/pinnedoff.svg'
import SortIcon from '../../assets/img/icons/sort.svg'
import AlertIcon from '../../assets/img/icons/alerton.svg'
import OffwristIcon from '../../assets/img/icons/offwrist.svg'
import SortListIcon from '../../assets/img/icons/sort_list.svg'
import '../../assets/css/patient-list.css'
import '../profile/patient/reminders.scss'
import { convertStringToClassName } from "utils";
import { DetectionAlerts } from "components/Modals/DoctorAlerts";
import PatientStatusComponent from "pages/doctor's patient/patient-status/patient-status.component";
import BatteryIconComponent from "components/icons/battery-icon/battery-icon.component";
import useOnClickOutside from "hooks/useOnClickOutside";
import { getDbMeasureVal } from "utils/formaters";
const MAX_DETECTIONS_DISPLAY = 3;

const Doctor = (props) => {
    const { patients, socketPatientsData, measurementsFromDB } = useSelector(state => state)
    const [searchedPatients, setSearchedPatients] = useState([])
    const [isCardsView, setIsCardsView] = useState(localStorage.getItem('view') === 'list' ? true : false)
    const [pinedPatients, setPinedPatients] = useState(JSON.parse(localStorage.getItem('pinedPatients') || '[]'))
    const navigate = useNavigate()
    let sortedByAlertsLength, sortByRemindAlertsLength, sortByPinLength
    const approvedPatients = getApprovedPatient(patients)
    const [displaySortMenu, setDisplaySortMenu] = useState()
    const refSortMenu = useRef()
    useOnClickOutside(refSortMenu, closeSortNav);
    const [sortKey, setSortKey] = useState('')

    function getApprovedPatient(patients) {
        const pinedPatients = JSON.parse(localStorage.getItem('pinedPatients') || '[]')
        return patients?.filter(p => p.status === 'approved').map(p => ({
            ...p,
            name: `${p.firstName} ${p.lastName}`,
            pinStatus: pinedPatients.includes(p.id)
        }))
    }

    const patinetRowClick = (patient) => {
        navigate(`/patient/${patient.id}/Dashboard`);
    }

    const handleStatus = (event, id) => {
        event.stopPropagation();
        let pinedPatients = JSON.parse(localStorage.getItem('pinedPatients') || '[]');
        if (pinedPatients.includes(id)) {
            pinedPatients = pinedPatients.filter((pId) => pId !== id)
        } else {
            pinedPatients.push(id)
        }

        localStorage.setItem('pinedPatients', JSON.stringify(pinedPatients));
        setPinedPatients(pinedPatients)
    }

    const handleView = () => {
        setIsCardsView(!isCardsView)
        localStorage.setItem('view', !isCardsView ? 'list' : 'card')
    }

    const getDetectionsTypes = (alerts, afFromSocket) => {
        const arrythmiaAlerts = alerts.filter(a => ARRYTHMIA_EVENTS[a.eventType])
        const sortedByHighPriority = arrythmiaAlerts.sort((a, b) => ARRYTHMIA_EVENTS[b.eventType]?.priority -  ARRYTHMIA_EVENTS[a.eventType]?.priority)
        const eventsMap = {};
        if(afFromSocket) eventsMap[ARRYTHMIA_EVENT_TYPES.PPG_AF] = {eventType: ARRYTHMIA_EVENT_TYPES.PPG_AF}
        let index = 0;
        while(Object.keys(eventsMap).length < MAX_DETECTIONS_DISPLAY && index < sortedByHighPriority.length){
            const { eventType, rt_session_id } = sortedByHighPriority[index]
            if(!eventsMap[eventType]) {
                eventsMap[eventType] = {eventType, rt_session_id}
            }
            index++;   
        }
        return Object.values(eventsMap);
    }

    const renderValue = (patient, sockeData, key, label, dbAlerts, remindAlerts, index) => {
        if (key === 'status') {
            return <PatientStatusComponent sockeData={sockeData} dbAlerts={dbAlerts} remindAlerts={remindAlerts} />
        }
        
        if (key === 'detection_alerts') {
            return (
                <div className="detection-alerts-dashboard">
                    <span className="list-none patient-list-label">{`${label}:`}</span>
                    <DetectionAlerts patientId={patient.id} alerts={getDetectionsTypes(dbAlerts, sockeData?.onAlert?.AF)}/>
                </div>
            )
        }
        if (key === 'Pin') {
            return (
                <span className="pin" onClick={(e) => handleStatus(e, patient.id)}>
                    <img key={patient.id} alt="pinStatus" src={patient.pinStatus ? pinnedon : pinnedoff} />
                </span>
            )
        }
        if (['Body Temp', 'SpO2', 'Blood Pressure', 'Respiratory Rate', 'Pulse Rate'].includes(key)) {
            const patientLastEvents = measurementsFromDB[patient.id]
            const dbVal = getDbMeasureVal(dashboardKeys[key], patientLastEvents?.[dashboardKeys[key]], patient)
            let socketVal = sockeData?.[key]?.value
            if(key === 'Body Temp' && socketVal) socketVal = Number(socketVal).toFixed(1)
            const value =  socketVal || dbVal || '--'
            return (
                <div style={{ display: 'flex', width: '100%' }}>
                    <div className="left-text d-flex">
                        <span className="list-none patient-list-label">{`${label}:`}</span>
                        <TextTransition
                            springConfig={presets.wobbly}
                            style={{ color: sockeData?.onAlert?.[key] ? '#EF3054' : '#000000', fontSize: 20 }}
                            className="measure-value">
                            {value}
                        </TextTransition>
                        <span style={{ marginTop: 5, marginLeft: 5, color: '#000000', display: !sockeData?.[key]?.value ? 'none' : 'inline' }}>{key === 'SpO2' ? '%' : sockeData?.[key]?.unit}</span>
                    </div>
                </div>
            )
        }
        const titles = {phoneNumber: 'Phone:', idNumber: 'ID:'}
        if (['name', 'idNumber', 'phoneNumber'].includes(key)) {
            return <div className={`id-text ${key} text-start`}>
                <div className="name-container">
                    <span className="list-none pr-2">{titles[key]}</span> 
                    <span>{patient[key]}</span>
                </div>
                {key === 'phoneNumber' && <div className="line list-none" />}
            </div>
        }
    }

    const renderDetectionsInfoFlag = () => (
        <span className='info-flag mx-1'>
            ?
            <span className='info-text detection-wrap'>
                <div className="info-detection info-ppg">
                    <span className="detection-legend" />
                    <span>PPG Detected</span>
                </div>
                <div className="info-detection">
                    <span className="detection-legend" />
                    <span>ECG Detected</span>
                </div>
            </span>
        </span>
    )

    const sortByBattery = (patients) => (
        patients.sort((a, b) => {
            const batteryA = +socketPatientsData?.[a.id]?.batteryPercentage?.value
            const batteryB = +socketPatientsData?.[b.id]?.batteryPercentage?.value
            if(batteryA === batteryB) return 0
            if(!batteryA) return 1
            if(!batteryB) return -1
            return batteryA - batteryB
        })
    )

    const isAlert = (p) => {
        return ((socketPatientsData?.[p.id]?.onAlert && Object.values(socketPatientsData?.[p.id]?.onAlert).length) || p.alerts?.length) 
    }

    const sortByAlerts = (patients) => {
        const getAlertsPriority = (p) => {
            if(isAlert(p)) return 2
            if(p.remindAlerts?.length) return 1
            return 0
        }
        return patients.sort((a, b) => {
            let priorityA = getAlertsPriority(a)
            let priorityB = getAlertsPriority(b)
            if(priorityB === priorityA) return 0
            return priorityB - priorityA
        })
    }

    const sortByConnection = (patients) => {
        const getConnectionPriority = (p) => {
            if (!socketPatientsData?.[p.id]) return 1
            if (socketPatientsData?.[p.id]?.connectionStatus?.value === 0) return 2
            if (socketPatientsData?.[p.id]?.offWrist) return 3
            return 0
        }
        return patients.sort((a, b) => {
            let priorityA = getConnectionPriority(a)
            let priorityB = getConnectionPriority(b)
            if(priorityB === priorityA) return 0
            return priorityB - priorityA
        })
    }

    const sortByPinedPatients = (patients) => {
        return patients.sort((a, b) => {
            return +b.pinStatus - +a.pinStatus
        })
    }

    function sortPatients() {
        if (searchedPatients.length) {
            const filterdPatients = approvedPatients.filter((item) => searchedPatients.includes(item.id))
            return filterdPatients
        }
        
        switch(sortKey){
            //we can insert the sort by pined patients inside the sorts and avoid duplicate sorts
            //i did not do it because it was not absolute decision if the pined patients should be in the top anyway
            case 'alerts_option':
                return sortByPinedPatients( sortByAlerts(approvedPatients) )
            case 'battery_option':
                return sortByPinedPatients( sortByBattery(approvedPatients) )
            case 'connect_option':
                return sortByPinedPatients( sortByConnection(approvedPatients) )
            case 'a_z':
                return sortByPinedPatients( approvedPatients.sort((a, b) => a.name.localeCompare(b.name)) )
            case 'z_a':
                return sortByPinedPatients( approvedPatients.sort((a, b) => b.name.localeCompare(a.name)) )
            default:
                return sortByPinedPatients( sortByAlerts(approvedPatients) )
        }
    }

    function closeSortNav(){
         setDisplaySortMenu()
    }

    const handleOpenSortNav = (field) => {
        let updateSortMenu;
        if(displaySortMenu !== field){
            updateSortMenu = field
        }
        setDisplaySortMenu(updateSortMenu)
    }

    const selectSortKey = (sortKey) => {
        setSortKey(sortKey)
        closeSortNav()
    }

    const renderSortNav = (field) => {
        const sortOptions = {
            status: [
                {key: 'alerts_option', label: 'Alerts detected', icon: <img className="status-icon alert-icon" src={AlertIcon} alt="alert" />},
                {key: 'battery_option', label: 'Battery status', icon: <BatteryIconComponent />},
                {key: 'connect_option', label: 'Connectivity', icon: <img className="status-icon connect-icon" src={OffwristIcon} alt="alert" />}
            ],
            name: [
                {key: 'a_z', label: 'A to Z'},
                {key: 'z_a', label: 'Z to A'}
            ]
        }
        
        return(
            <span className="wrap-sort-nav">
                <img className={`ignore-outside-listener pointer ms-1 sort-toggle ${displaySortMenu === field && 'open'}`} onClick={() => handleOpenSortNav(field)} src={SortIcon} alt='sort' />
                {displaySortMenu && displaySortMenu === field &&
                    <div ref={refSortMenu} className="sort-nav">
                        <div className="sort-nav-title"><img src={SortListIcon} alt="sort" /><span>Sort by</span></div>
                        {sortOptions[field].map(option => <div key={option.key} className="sort-nav-option" onClick={() => selectSortKey(option.key)}>
                            {option?.icon && <span className="sort-nav-icon">{option?.icon}</span>}
                            <span>{option.label}</span>
                        </div>)}
                    </div>
                }
            </span>
        )
    }

    return (
        <div style={{ backgroundColor: '#F2F6FA' }}>
            <SubNavbar title={'Patient List'} subPages={['Patient List']}>
                {/* according to Eyal we are not supporting the cards view */}
                {/* <div className="hamburger-container">
                    <img className="first-img" src={isCardsView ? List : ListOn} onClick={() => handleView()} alt='Hamburger' />
                    <div className="line"></div>
                    <img style={{ cursor: 'pointer' }} src={isCardsView ? Grid : GridOff} onClick={() => { handleView() }} alt='Hamburger' />

                </div> */}
            </SubNavbar>
            <div>
                <Container className={`${isCardsView ? 'cards-view' : 'list-view'}`} fluid style={{ maxWidth: '2000px', minWidth: '70%' }}>
                    <Row className="mt-3" >
                        <Col xl="12">
                            <div className="">
                                <CardHeader className="border-0 " style={{ padding: 10 }}>
                                    <Select options={approvedPatients?.map((p, i) => { return { value: i, label: p.name, id: p.id } })}
                                        isClearable isMulti
                                        placeholder="Search"
                                        onChange={(choice) => {
                                            const idSelectedPatients = choice.map(c => c.id)
                                            setSearchedPatients(idSelectedPatients)
                                        }}
                                        components={{ DropdownIndicator }} />
                                </CardHeader>
                                <Table className="align-items-center table-flush patients-table" responsive style={{ overflow: 'hidden' }}>
                                    <thead className="thead-light card-none">
                                        <tr>{columns.map(({ label, value }) => {
                                            return (
                                                label === 'pin' ?
                                                    <th className="box" key={label} scope="col">
                                                        <img alt="pin" src={pinnedon} />
                                                    </th>
                                                    :
                                                    <th scope="col"
                                                        className={`box ${convertStringToClassName(value)}`}
                                                        key={label}>{label}
                                                        {value === 'detection_alerts' && renderDetectionsInfoFlag()}
                                                        {['name', 'status'].includes(value) && renderSortNav(value)}
                                                    </th>
                                            )
                                        }
                                        )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortPatients().map((patient, index) =>
                                            <Fragment key={patient.id}>
                                                {
                                                    index === sortedByAlertsLength + sortByRemindAlertsLength + sortByPinLength &&
                                                    <tr className="cards-line"></tr>
                                                }
                                                <tr onClick={() => patinetRowClick(patient, index)} className="doctor-table-row" key={patient.id}>
                                                    {columns.map(({ value: key, label }) => {
                                                        const patientSocketData = socketPatientsData?.[patient.id]
                                                        const dbAlerts = patient.alerts
                                                        return (
                                                            <td className={`${key}-td`} key={key}>
                                                                {renderValue(patient, patientSocketData, key, label, dbAlerts, patient.remindAlerts, index)}
                                                            </td>)
                                                    })}
                                                </tr>
                                            </Fragment>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
export default Doctor