
import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import { Container, Row } from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import routes from "routes.js";
import HeartLogo from "assets/img/theme/heart.svg"

const Auth = ({setUser}) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.filter((prop) => prop.layout === "/auth").map((prop, key) => {
        const ComponentRender = prop.component
        return (
          <Route
            path={prop.layout + prop.path}
            element={<ComponentRender setLoggedUser={setUser} />}
            key={key}
          />
        );
    });
  };

  return (
    <>
      <AuthNavbar />
      <div className="main-content" ref={mainContent}>
        <div style={{backgroundColor:'#003F7B',height:'40vh',position:'relative',overflow:'hidden'}} >
        <img alt="logo" src={HeartLogo} style={{height:'130%',position:'absolute',right:'-5%',top:'-40%'}}/>
        </div>
        <Container className="mt--9 pb-5">
          <Row className="justify-content-center">
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/auth/login" />} />
            </Routes>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
};

export default Auth;
