import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// import arTranslation from './ar-AR.json';
// import caTranslation from './ca.json';
// import deTranslation from './de-DE.json';
import elTranslation from './el-EL.json';
import enTranslation from './en-US.json';
import esTranslation from './es-ES.json';
import frTranslation from './fr-FR.json';
// import heTranslation from './he-IL.json';
// import hiTranslation from './hi-IN.json';
// import itTranslation from './it-IT.json';
// import phiTranslation from './phi.json';
// import plTranslation from './pl-PL.json';
// import ptTranslation from './pt-PT.json';
// import roTranslation from './ro-RO.json';
// import ruTranslation from './ru-RU.json';
import thTranslation from './th-TH.json';
// import trTranslation from './tr-TR.json';
// import viTranslation from './vi-VN.json';
// import zhTranslation from './zh-CN.json';
import fallbackTranslation from './fallback-translations.json';


export const LANGUAGES = [
  { languageTag: 'en', text: 'English', langCode: 0, isRTL: false }, //English
  { languageTag: 'he', text: 'עברית', langCode: 18, isRTL: false /*change to true after translation ready*/ }, //Hebrew
  { languageTag: 'ar', text: 'عربي', langCode: 19, isRTL: false /*change to true after translation ready*/}, //Arabic
  { languageTag: 'fr', text: 'Français', langCode: 3, isRTL: false }, //French
  { languageTag: 'de', text: 'Deutsch', langCode: 1, isRTL: false }, //German
  { languageTag: 'hi', text: 'हिन्दी', langCode: 13, isRTL: false }, //Hindi
  { languageTag: 'it', text: 'Italiano', langCode: 2, isRTL: false }, //Italian
  { languageTag: 'ja', text: '日本', langCode: 16, isRTL: false }, //Japanese
  { languageTag: 'nl', text: 'Dutch', langCode: 8, isRTL: false }, //Dutch
  { languageTag: 'phi', text: 'Filipino', langCode: 7, isRTL: false }, //Phillipine
  { languageTag: 'pt', text: 'Português', langCode: 6, isRTL: false }, //Portuguese
  { languageTag: 'ru', text: 'Русский', langCode: 15, isRTL: false }, //Russian
  { languageTag: 'tr', text: 'Türkçe', langCode: 4, isRTL: false }, //Turkish
  { languageTag: 'vi', text: 'Tiếng Việt', langCode: 0, isRTL: false }, //Vietnamese
  { languageTag: 'zh', text: '中国人', langCode: 14, isRTL: false }, //Chinese
  { languageTag: 'es', text: 'Español', langCode: 9, isRTL: false }, //Spanish
  { languageTag: 'ca', text: 'Català', langCode: 5, isRTL: false }, //Catalan
  { languageTag: 'el', text: 'Ελληνικά', langCode: 17, isRTL: false }, //Greek
  { languageTag: 'pl', text: 'Polski', langCode: 10, isRTL: false }, //Polish
  { languageTag: 'ro', text: 'Română', langCode: 11, isRTL: false }, //Romanian
  { languageTag: 'hr', text: 'Hrvatski', langCode: 12, isRTL: false }, //Croatian
  { languageTag: 'th', text: 'ไทย', langCode: 20, isRTL: false }, //Thailand
  { languageTag: 'cz', text: 'čeština', langCode: 21, isRTL: false }, // Czech
  { languageTag: 'sk', text: 'slovenský', langCode: 22, isRTL: false }, // Slovak
];

const resources = {
  fallback: { translation: fallbackTranslation },
  // ar: { translation: arTranslation },// arabic
  // ca: { translation: caTranslation },
  // de: { translation: deTranslation },
  el: { translation: elTranslation },// greek
  en: { translation: enTranslation },
  es: { translation: esTranslation },// spanish
  fr: { translation: frTranslation },
  // he: { translation: heTranslation },
  // hi: { translation: hiTranslation },
  // it: { translation: itTranslation },
  // phi: { translation: phiTranslation },
  // pl: { translation: plTranslation }, // polish
  // pt: { translation: ptTranslation },
  // ro: { translation: roTranslation }, // romanian
  // ru: { translation: ruTranslation },
  th: { translation: thTranslation },
  // tr: { translation: trTranslation },
  // vi: { translation: viTranslation },
  // zh: { translation: zhTranslation },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'fallback',
    interpolation: {
      escapeValue: false,
    },
  });

  
export const isRTL = () => {
  const currentLanguage = i18n.language;
  const lang = Object.values(LANGUAGES).find(l => l.languageTag === currentLanguage)
  return lang.isRTL
}

export const t = i18n.t;

export const changeLanguage = (language) => {
  i18n.changeLanguage(language);
  return {language: i18n.language, isRTL: isRTL()}
};

export default i18n;
