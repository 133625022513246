import React, { useEffect, useRef, useState } from "react";
import _ from 'lodash'
import { Container, Row, Col } from "reactstrap";
import { MeasureBox, MassagesBox, NotificationsBox, ChartBox } from 'components/Cards'
import SubNavbar from "components/Navbars/SubNavbar";
import { initialMesutments, MONTHLY_CHARTS_TITLES } from "consts.js"
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { DailyChart } from '@cardiacsense/report-templates-npm'
import MonthlyChart from "components/Charts/Monthly";
import { UNIT_KEYS } from "consts";
import { useSelector } from "react-redux";
import CaloriesAndSteps from "components/Cards/calories-and-steps";
import { useReactToPrint } from 'react-to-print';
import { DASHBOARD_FIRST_ROW, DASHBOARD_SECOND_ROW } from "consts";
import { setupMeasurementsFromServerToDashboard } from "utils/formaters";


const Dashboard = (props) => {
  const { patientMeasurments: measurments, patientAlerts: alerts, measurementsFromDB } = useSelector(state => state)
  const emptyMeaurments = initialMesutments(props.user.data)
  const [monthlyCharts, setMonthlyCharts] = useState([])
  const units = _.mapValues(emptyMeaurments, ({ unit }) => unit)
  const chartBoxRef = useRef()
  const {configuration} = props?.user?.data?.appConfigs
  const patientId= props?.user?.data?.id

  const pageStyle = `
  @page {
    size: 80mm 50mm;
    margin: 100px;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

useEffect(() => {
  if(!measurments?.isFromApp && measurementsFromDB[patientId]){
    setupMeasurementsFromServerToDashboard(measurementsFromDB[patientId], props?.user?.data, units)
  }
}, [measurementsFromDB])

  const handleBoxClick = (title) => {
    const chartIndex = monthlyCharts.findIndex(chartTitle => chartTitle === title)
    if (chartIndex > -1) {
      const removed = [...monthlyCharts]
      removed.splice(chartIndex, 1)
      setMonthlyCharts(removed)
    } else {
      setMonthlyCharts(prev => [title, ...prev])
    }
  }

  const handlePrint = useReactToPrint({
    pageStyle: () => pageStyle,
    content: () => chartBoxRef.current,
  });

  const isConfigOff = (item) => {
    const key = measurments[item]?.configKey || measurments[item]?.key || measurments[item]?.title?.toLocaleLowerCase().replace(' ','_')
    return configuration?.measurements?.[key] === 'off'
  }

  const getDbMeasureVal = (item) => {
    return measurementsFromDB[patientId]?.[measurments?.[item]?.key]
  }

  const getInnerContentVal = (item) => {
    const key = measurments[item]?.innerContent?.key
    if(!key) return
    return measurementsFromDB[patientId]?.[key]
  }

  return (
    <div>
      <SubNavbar title={'Home'} subPages={['Home']} />
      <div ref={chartBoxRef}>
        <Container fluid style={{ maxWidth: '2000px' }}>

          <Row className="mt-5" style={{ paddingLeft: '10px' }} >
            <Col xl="9">
              <Row>
                <Col style={{ padding: '0px 4px' }}>
                  <NotificationsBox alerts={alerts} />
                </Col>
                {DASHBOARD_FIRST_ROW.map((item)=> 
                <Col key={measurments[item]?.title} style={{padding:'0px 4px'}} >
                <MeasureBox
                  value={getDbMeasureVal(item)}
                  {...measurments[item]}//socket data overide the value
                  alert={alerts[measurments?.[item]?.title]}
                  disabled={isConfigOff(item)}
                  onClick={handleBoxClick}
                  innerContentVal={getInnerContentVal(item)}
                />
              </Col>
                )}
              </Row>
              <Row className="mt-2">
                {DASHBOARD_SECOND_ROW.map((item) =>
                  <Col key={measurments[item]?.title} style={{ padding: '0px 4px' }} >
                    <MeasureBox
                      {...measurments[item]}
                      alert={alerts[measurments[item]?.title]}
                      disabled={isConfigOff(item)}
                      onClick={handleBoxClick} />
                  </Col>
                )}
              </Row>
            </Col>
            <Col xl="3">
              <CaloriesAndSteps patientId={patientId} steps={measurments.Steps} calories={measurments.Calories} configurations={configuration} />
              <MassagesBox readyState={0/* readyState */} user={props.user} />
            </Col>

          </Row>

          <TransitionGroup>
            {monthlyCharts.map(title =>
              <CSSTransition key={title} timeout={500} classNames="chart-card">
                <ChartBox key={title} title={`Monthly ${MONTHLY_CHARTS_TITLES[title] || title}`} token={props.user.jwtToken} handlePrint={handlePrint} patientId={patientId}>
                  <MonthlyChart
                    patientId={patientId}
                    type={title}
                    thresholds={props.user.data.thresholds}
                    unit={props.user.data[UNIT_KEYS[title]]} />
                </ChartBox>
              </CSSTransition>
            )}
          </TransitionGroup>

          <ChartBox title={'Daily Chart'} token={props.user.jwtToken} handlePrint={handlePrint} patientId={patientId}>
            <DailyChart units={units} props={props} configurations={configuration} />
          </ChartBox>
        </Container>
      </div>
    </div>
  );
};

export default Dashboard