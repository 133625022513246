import axios from 'axios'
import {store} from '../store'
import { toast } from 'react-toastify';
import { setAppConfigurations, setCountries, setFotaList, setVersions } from 'store/actions';

const dispatch = store.dispatch
const getJwt = () => store.getState().user?.jwtToken
const getUserMail = () => store.getState().user?.data?.username

export const getPatientByEmailOrPIdOrUId = async (userId, userName, patientId, watchName, showToast = true) => {
    try {
        console.log('userId, userName, patientId', userId, userName, patientId, watchName)
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/app/testing/get-details`, 
        {userId, userName, patientId, watchName},
        { headers: { 'Authorization': `Bearer ${getJwt()}` } })
        console.log('res', res)
        return res.data
    } catch (error) {
        console.log('error', error)
        if(showToast) toast.error(error.message)
    }
}

export const deleteUser = async (userId, showToast = true) => {
    try {
        console.log('userId', userId)
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/app/testing/delete-user`, 
        {userId},
        { headers: { 'Authorization': `Bearer ${getJwt()}` } })
        console.log('res', res)
        if(showToast) toast.success('user deleted successfuly')
        return res.data
    } catch (error) {
        console.log('error', error)
        if(showToast) toast.error(error.message)
    }
}

export const getCountriesAndConfig = async (userId) => {
    console.log('userId', userId)
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/app/testing/get-all-countries-and-config`,{}, 
    { headers: { 'Authorization': `Bearer ${getJwt()}` } })
    console.log('res', res)
    const {configurations, countries, versions} = res?.data
    dispatch( setCountries(countries) )
    dispatch( setVersions(versions) )
    dispatch( setAppConfigurations(configurations) )
    return res.data
}

export const updateOrAddConfigurations = async (configuration, showToast=true) => {
    try {
        console.log('configuration', configuration)
        const apiEndpoint = configuration.id ? 'update-app-config' : 'add-app-config'
        const updatedConf = await axios.post(`${process.env.REACT_APP_API_URL}/app/testing/${apiEndpoint}`,{...configuration}, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
        // dispatch(setPhysicians(resSelectedDoctors.data))
        console.log('updatedConf', updatedConf)
        if(showToast) toast.success('configurations updated successfuly')
        return updatedConf.data
    } catch (error) {
        console.log('error', error)
        if(showToast) toast.error(error.message)
    }
}

export const changePasswordForUser = async (userId, password, showToast) => {
    try {
        console.log('userId, password', userId, password)
        const updatedPass = await axios.post(`${process.env.REACT_APP_API_URL}/app/testing/change-password-for-user`,{userId, password}, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
        console.log('updatedPass', updatedPass)
        if(showToast) toast.success('users password changed to: ' + password)
        return updatedPass.data
    } catch (error) {
        console.log('error', error)
        if(showToast) toast.error(error.message)
    }
}

export const updatePatientOrDocAndUser = async ({patient, user, doctor}, showToast=true) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/app/testing/update-full-user`,{patient, user, doctor}, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
        console.log('res', res)
        if(showToast) toast.success('data updated successfuly')
        return res.data
    } catch (error) {
        console.log('error', error)
        if(showToast) toast.error(error.message)
    }
}

export const unlockUser = async (userId, showToast) => {
   try {
     const unlocked = await axios.post(`${process.env.REACT_APP_API_URL}/app/testing/unlock-user`,{userId}, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
     if(showToast) toast.success('user successfully unlocked')
     return unlocked.data
   } catch (error) {
    console.log('error', error)
    if(showToast) toast.error(error.message)
   }
}

export const unlinkWatch = async (patientId, showToast) => {
    console.log('patientId', patientId)
   try {
     const unlinked = await axios.post(`${process.env.REACT_APP_API_URL}/app/testing/unlink-watch-from-patient`,{patientId}, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
     if(showToast) toast.success('user successfully unlinked from watch')
     return unlinked.data
   } catch (error) {
    console.log('error', error)
    if(showToast) toast.error(error.message)
   }
}

export const sendResetPasswordMail = async (userMail, showToast = true) => {
   try {
     const unlinked = await axios.post(`${process.env.REACT_APP_API_URL}/app/user/forgot-password`,{email: userMail, copyMailToAdmin: getUserMail()}, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
     if(showToast) toast.success('sent reset password mail successfully')
     return unlinked.data
   } catch (error) {
    console.log('error', error)
    if(showToast) toast.error(error.message)
   }
}

export const sendMessageToUsers = async (msg, showToast=true) => {
   try {
     await axios.post(`${process.env.REACT_APP_API_URL}/app/admin/send-message`,msg, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
     if(showToast) toast.success('Message successfully sent')
   } catch (error) {
    console.log('error', error)
    if(showToast) toast.error(error.message)
   }
}

export const updateCountryConfiguration = async (countryCode, appConfigId, showToast) => {
   try {
     const updatedCountry = await axios.post(`${process.env.REACT_APP_API_URL}/app/testing/update-country-app-config-id`,{countryCode, appConfigId}, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
     if(showToast) toast.success('user successfully updated country configurations')
     return updatedCountry.data
   } catch (error) {
    console.log('error', error)
    if(showToast) toast.error(error.message)
   }
}

export const getFotaList = async () => {
   try {
     const fotaList = await axios.post(`${process.env.REACT_APP_API_URL}/app/testing/get-all-fota`,{}, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
     dispatch( setFotaList(fotaList?.data) )
     return fotaList.data
   } catch (error) {
        console.log('error', error)
    }
}

export const addFotaToCountry = async (fota_version_id, country, showToast = true) => {
   try {
     const res = await axios.post(`${process.env.REACT_APP_API_URL}/app/testing/attribute-fota-to-country`,{fota_version_id, country}, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
     if(showToast) toast.success('added country to fota successfully')    
     return res.data
   } catch (error) {
        console.log('error', error)
        if(showToast) toast.error(error.message)
    }
}

export const removeFotaFromCountry = async (fota_version_id, country, showToast = true) => {
   try {
     const res = await axios.post(`${process.env.REACT_APP_API_URL}/app/testing/remove-fota-from-country`,{fota_version_id, country}, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
     if(showToast) toast.success('removed country from fota successfully')    
     return res.data
   } catch (error) {
        console.log('error', error)
        if(showToast) toast.error(error.message)
    }
}

export const addFota = async (newFota, showToast = true) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/app/watch-firmware-update/add-fota`,{...newFota}, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
        console.log('res', res)
        const resNewFota = res?.data?.newFota
        console.log('resNewFota', resNewFota)
        const fotaList = store.getState().fotaList
        console.log('fotaList from api', fotaList)
        dispatch( setFotaList([resNewFota, ...fotaList]))
        if(showToast) toast.success('added fota successfully')
        return resNewFota
    } catch (error) {
        console.log('error', error)
        if(showToast) toast.error(error.message)
    }
}

export const deleteFota = async (fotaId, showToast = true) => {
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/app/testing/delete-fota-by-id`,{id: fotaId}, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
        if(showToast) toast.success('deleted fota successfully')
    } catch (error) {
        console.log('error', error)
        if(showToast) toast.error(error.message)
    }
}

export const addVersion = async (version, showToast = true) => {
    console.log('version from api', version)
    try {
        const updatedVersions = await axios.post(`${process.env.REACT_APP_API_URL}/app/version/create-version`,{...version}, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
        if(showToast) toast.success('version added successfully')
        console.log('updatedVersions', updatedVersions.data)
        dispatch( setVersions(updatedVersions.data) )
    } catch (error) {
        console.log('error', error)
        if(showToast) toast.error(error.message)
    }
}

export const inactiveVersion = async (version, showToast = true) => {
    console.log('version from inactive api', version)
    try {
        const updatedVersions = await axios.post(`${process.env.REACT_APP_API_URL}/app/version/inactive-version`,{...version}, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
        if(showToast) toast.success('version for this country inactivated successfully')
        console.log('updatedVersions', updatedVersions.data)
        dispatch( setVersions(updatedVersions.data) )
    } catch (error) {
        console.log('error', error)
        if(showToast) toast.error(error.message)
    }
}

export const getPeripheralData = async (peripheralId, peripheralName, patientId, showToast = true) => {
    console.log('from getPeripheralData api - peripheralName:', peripheralName, 'peripheralId:', peripheralId, 'patientId', patientId)
    try {
        const peripheralData = await axios.get(`${process.env.REACT_APP_API_URL}/app/peripheral/get-peripheral-data?peripheralName=${peripheralName}&peripheralId=${peripheralId}&patientId=${patientId}`, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
        console.log('peripheralData', peripheralData.data)
        return peripheralData?.data
        // dispatch( setVersions(updatedVersions.data) )
    } catch (error) {
        console.log('error', error)
        if(showToast) toast.error(error.message)
    }
}
