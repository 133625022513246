
import React,{useEffect} from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import { Container } from "reactstrap";
import axios from "axios";
import routes from "routes.js";
import { setMonthlyData } from "store/actions";
import { profileSections } from "consts";
import { getFirstDayInMonth } from "utils";
import Sidebar from "components/Sidebar/Sidebar.js";
import PatientNavbar from "components/Navbars/PatientNavbar.js";
import PatientFooter from "components/Footers/PatientFooter.js";
import SubNavbar from "components/Navbars/SubNavbar";
import SectionsNavbar from "components/Navbars/SectionsNavbar";
import WebsocketPatient from "services/WebsocketPatient";
import { useDispatch } from "react-redux";
import { getSelectedDoctors } from "api/api-manager";
import { emptyMonthlyData } from "variables/charts";

  const titles = {
    profile: 'My Profile',
    reports: 'Reports',
    archive: 'Reports/Reports Archive'
  }

const Patient = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const isStaticPage = location.pathname.split('/')[1]==='static'
  const dispatch = useDispatch()


  const getProfileSections = () => {
    const showTrhesholds = props?.user?.data?.appConfigs?.configuration?.alerts?.thresholds === 'on'
    const sectionsFilteredByThresholdsConfig = profileSections.filter(s => s.link !== 'thresholds' || showTrhesholds)
    return sectionsFilteredByThresholdsConfig
  }

  const subSections = {
    profile: getProfileSections(),
  }

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    const day = getFirstDayInMonth()
    const {id: patientId} = props.user.data
    getSelectedDoctors()
    
    !isStaticPage && axios.post(
      `${process.env.REACT_APP_API_URL}/app/measurements/get-monthly`,
        { day },
          { headers: {'Authorization': `Bearer ${props.user.jwtToken}` } }  
        )
    .then(res=>{
      const data = res.data || emptyMonthlyData(day)
      dispatch( setMonthlyData(data, day, false, patientId) )
    })
    .catch(err=>{
      console.log('error fetching monthly data')
      dispatch( setMonthlyData({},day,false,patientId) )
    })
    }, [])
    
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      const [upperLayout, innerLayout] = prop.layout.split('/').filter(layout=>layout)
      const ComponentRender = prop.component
      if(upperLayout === "patient" && innerLayout){

        const ProfileWrapper = ()=>
        <div>
          <SubNavbar title={titles[innerLayout]} subPages={[titles[innerLayout]]}/>
          <SectionsNavbar upperPath={innerLayout} title={prop["section-name"]} subSections={subSections[innerLayout]} />
          <ComponentRender user={props.user.data} setUser={props.setUser} jwtToken={props.user.jwtToken} />
        </div>
        
        return (
          <Route
            path={prop.path}
            element={<ProfileWrapper/>}
            key={key}
          />
        );
      } 
      if (upperLayout === "patient") {
        return (
          <Route
            path={prop.path}
            element={<ComponentRender user={props.user} setUser={props.setUser} />}
            key={key}
          />
        );
      } 
       
      else {
        return null;
      }
    });
  };

return (
    <>
      <PatientNavbar {...props} display={!isStaticPage}/>
      <Sidebar
        {...props}
        routes={routes}
        display={!isStaticPage}
      />
      <div className="main-content" ref={mainContent}>
        <Container fluid>
        {!isStaticPage && <div style={{height:'93px'}} />}
        </Container>
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
        <Container fluid>
          <PatientFooter />
        </Container>
      </div>
      {
        !isStaticPage ?<WebsocketPatient user={props.user} /> :
        null
      }
      
    </>
  );
};
export default Patient
