import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Row } from "reactstrap"
import './realtime-modal.scss'
import HrGraphBackgroundComponent from '../Modals/hr-graph-background.component'
import HrGraphXAxisComponent from '../Modals/hr-graph-x-axis.component'
import { ecgDataStream } from 'services/WebsocketDoctor';
import { allEqual, removeZeroes } from 'utils';
import { getMedicalAnswers } from 'utils/formaters'
import { questionsScore } from 'utils/questionsScore';
import { RealtimeTempReportComponent } from '@cardiacsense/report-templates-npm'
import useWebSocket from 'react-use-websocket';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { MESSAGE_PLATFORM, USER_TYPES, REALTIME_MESSAGE_ACTION, MESSAGE_TYPE, REAL_TIME_DISPLAY_STATUS, MEDICAL_QUESTIONS_CONDITAIONS as conditions } from 'consts';
import RealtimeMeasureBox from 'components/Cards/RealtimeMeasureBox';
import ViIcon from '../../assets/img/icons/vi.svg'
import { getPatientAppConfig, getPatientMedications } from 'api/api-manager';
import { t } from 'multi-language/multi-lang.service';

let seconds = [];
let tempSec = 0;
let interval;
const NUMBER_OF_SECONDS_TO_SHOW = 5
const RECEIVED_ECG_MEASUREMENTS = 128;
const RECEIVED_PPG_MEASUREMENTS = 64;

export default function Realtime({ patient, toggleModal, onAlert }) {
  const { user, connectionId, patientsChannels, patientsMedication, patientsConfiguration } = useSelector(state => state)
  const configurations = patientsConfiguration[patient.id]
  const medications = patientsMedication?.[patient.id] || []
  const { sendMessage, lastMessage } = useWebSocket(`${process.env.REACT_APP_SOCKET_URL}?id=${user.data.id}&user_type=${user.type}&platform=portal&jwtToken=${user.jwtToken}`, { share: true });
  let [displayedSeconds, setDisplayedSeconds] = useState([])
  const [rtMeasurements, setRtMeasurements] = useState({})
  const [showCollectingData, setShowCollectingData] = useState(true)
  const [isRtStarted, setIsRtStarted] = useState(false)
  const [showSeconds, setShowSeconds] = useState(false)
  const ecgDataSubscriber = useRef();
  const [showRealtime, setShowRealtime] = useState(false)
  const [isPatientNotResponded, setIsPatientNotResponded] = useState(false)
  const [isPatientDeclinedRequest, setIsPatientDeclinedRequest] = useState(false)
  const [realtimeStopped, setRealTimeStopped] = useState(false)
  const [measurementComplete, SetMeasurementComplete] = useState(false)
  const [alert, setAlert] = useState()
  const realtimeData = useRef([])
  const statusRtEnd = useRef()
  const medicalData = {}
  let patientQuestions = {}
  let timeoutPatientResponded
  conditions.forEach((condition, i) => medicalData[condition] = { key: i + 1, answer: patient[`question${i + 1}`] })
  patientQuestions = getMedicalAnswers(patient)
  const totalQuestionsScore = questionsScore(patient)
  const data = {
    patientProfile: patient,
    questionsScore: totalQuestionsScore,
    ecg_ppg: realtimeData.current,
    patientQuestions,
    statusRtEnd: statusRtEnd.current,
    medications
  }

  const sendStopRealtime = () => {

    sendMessage(JSON.stringify({
      type: MESSAGE_TYPE.REALTIME,
      action: REALTIME_MESSAGE_ACTION.END,
      from: {
        source_id: user.data.id,
        source_type: user.type,
        connection_id: connectionId,
        platform: MESSAGE_PLATFORM.PORTAL,
      },
      to: [{ channel_id: patientsChannels[patient.id], target_type: USER_TYPES.PATIENT, target_id: patient.id, platform: MESSAGE_PLATFORM.MOBILE }],
      data: { doctorName: `${user.data.firstName} ${user.data.lastName}` }
    }))
  }

  useEffect(() => {
    if (!patientsMedication?.[patient.id]) {
        getPatientMedications(patient.id)
    }
    if(!configurations){
      getPatientAppConfig(patient.id)
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  useEffect(() => {
    timeoutPatientResponded = setTimeout(() => {
      setIsPatientNotResponded(true)
      clearInterval(interval)
    }, 60000)

    ecgDataSubscriber.current = ecgDataStream.subscribe((data) => {
      if (data && !data.rtStopped) {
        setShowSeconds(true)
        clearTimeout(timeoutPatientResponded)
      }
      else {
        clearInterval(interval);
        setShowSeconds(false)
      }
      sendMessageToIframe(data)
    })

    return () => {
      clearTimeout(timeoutPatientResponded)
      unsubscribeEcgData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (toggleModal) {
      clearInterval(displayedSeconds);
      tempSec = 0;
      seconds = [];
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal])

  const unsubscribeEcgData = () => {
    ecgDataSubscriber.current?.unsubscribe()
  }


  const getStatusMessage = () => {
    if (isPatientNotResponded){
      return t('modals.realtime.not_responding')
    } else if(isPatientDeclinedRequest){
      return t('modals.realtime.declined')
    } else if (statusRtEnd.current === REAL_TIME_DISPLAY_STATUS.STOPPED_EXIT){
      return t('modals.realtime.failed')
    } else if (statusRtEnd.current === REAL_TIME_DISPLAY_STATUS.STOPPED_ECG_AF_DETECTED){
      return t('modals.realtime.completed')
    } else if (statusRtEnd.current === REAL_TIME_DISPLAY_STATUS.STOPPED_COMPLETED){
      return t('modals.realtime.completed')
    } else if(!isRtStarted){
      return t('modals.realtime.waiting')
    } else if(showCollectingData){
      return t('modals.realtime.collecting')
    }
  }

  const getStatusAfIcon = () => {
    let src;
    if (statusRtEnd.current === REAL_TIME_DISPLAY_STATUS.STOPPED_ECG_AF_DETECTED || statusRtEnd.current === REAL_TIME_DISPLAY_STATUS.STOPPED_COMPLETED){
      src = ViIcon
    }
    if(src) return <img width={45} alt='af-status' src={src} />
  }

  const sendMessageToIframe = (data) => {
    clearTimeout(timeoutPatientResponded)
    if (data && !data.rtStopped) {
      incrementDisplayedSeconds()
      setIsRtStarted(true)
      const ecgInvalidData = countOccurrences(data.ecg, 10000)
      const ppdInvalidData = countOccurrences(data.ppg, 10000)
      const ecgEmptyData = countOccurrences(data.ecg, 0)
      const ppdEmptyData = countOccurrences(data.ppg, 0)
      if (!(ecgInvalidData + ecgEmptyData > RECEIVED_ECG_MEASUREMENTS / 2 || ppdInvalidData + ppdEmptyData > RECEIVED_PPG_MEASUREMENTS / 2)) {
        setShowCollectingData(false)
      }
    }

    const iframeElement = document.getElementById('realTimeMessageIframe')
    if (iframeElement && iframeElement.contentWindow) {
      const { ecg, ppg, rtStopped, rtMeasurements } = data
      setRtMeasurements(rtMeasurements)
      if (rtStopped) {
        if(!statusRtEnd.current) {
          data.statusRtEnd = rtStopped
          statusRtEnd.current = rtStopped
        }
        unsubscribeEcgData()
        if(rtStopped === REAL_TIME_DISPLAY_STATUS.DECLINED_BY_PATIENT){
          return setIsPatientDeclinedRequest(true)
        }
        setRealTimeStopped(true)
        SetMeasurementComplete(true)
      }

      if (!rtStopped && allEqual(removeZeroes(ecg)) && allEqual(removeZeroes(ppg))) return // skips fake initial data
      if (!rtStopped) {
        realtimeData.current.push(data)
        setIsPatientNotResponded(false)
      }
      iframeElement.contentWindow.refreshChart({ ecg, ppg, rtStopped })
    }
  }

  const countOccurrences = (arr, val) => {
    return arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
  }

  const incrementDisplayedSeconds = () => {
    if (seconds.length >= NUMBER_OF_SECONDS_TO_SHOW) {
      seconds = seconds.slice(1).concat(tempSec)
    } else {
      seconds.push(tempSec)
    }
    setDisplayedSeconds([...seconds])
    tempSec++;
  }

  const handleClose = () => {
    clearInterval(interval)
    setIsPatientNotResponded(false)
    if (!realtimeStopped) {
      sendStopRealtime()
    }
    toggleModal(false)
  }

  const handleSave = () => {
    if (!realtimeStopped) {
      sendStopRealtime()
      if(!statusRtEnd.current) {
        statusRtEnd.current = REAL_TIME_DISPLAY_STATUS.STOPPED_BY_DOCTOR
        data.statusRtEnd = REAL_TIME_DISPLAY_STATUS.STOPPED_BY_DOCTOR
      }
    }
    setShowRealtime(true)
    setRealTimeStopped(false)
    uplaodFile()
  }

  const uplaodFile = async () => {
    const timestamp = new Date().getTime()
    const fileName = `realtime-doctorId-${user.data.id}-pId-${patient.id}-${timestamp}.json`
    await axios.post(`${process.env.REACT_APP_API_URL}/app/doctor/upload-realtime-json-to-s3`,
      {
        fileName, data
      }, { headers: { 'Authorization': `Bearer ${user.jwtToken}` } })
  }
  let box = document.querySelector('.box');
  let width = box?.clientWidth;
  return (
    <Modal id='realtime-modal' style={{ maxWidth: '1455px', width: '60%', flexDirection: 'column' }}
      className="modal-dialog-centered " size="lg"
      isOpen={true}
      toggle={() => toggleModal(undefined)}
    >


      <div className='realtime-content'>
        <div className='box wrap-report-component'>{showRealtime && <div><RealtimeTempReportComponent data={data} isPdf={true} width={width} /></div>}</div>
        {!showRealtime &&
          <div style={{ height: '60vh' }}>
            <div style={{ fontSize: 25, marginLeft: 25, marginTop: 20, color: '#002D66', marginBottom: 20 }} >{t('modals.realtime.data_title')}</div>
            <div id='rt-personal-data' style={{ maxWidth: '100%', backgroundColor: 'rgb(235, 235, 235)', height: 50, justifyContent: 'center', paddingTop: 15 }}>
              <i style={{ color: '#060606', marginRight: 7, fontSize: 15, marginLeft: 20 }}>{t('personal_information.name')}:</i>
              <i style={{ color: '#3FA842', marginRight: 7, fontSize: 15, marginTop: 20 }}> {`${patient.firstName} ${patient.lastName}`}</i>
              <i style={{ color: '#060606', marginRight: 7, fontSize: 15, marginLeft: 20, marginTop: 20 }}>{t('personal_information.gender')}:</i>
              <i style={{ color: '#3FA842', marginRight: 7, fontSize: 15, marginTop: 20 }}> {`${patient.gender}`}</i>
              <i style={{ color: '#060606', marginRight: 7, fontSize: 15, marginLeft: 20, marginTop: 20 }}>{t('personal_information.id')}:</i>
              <i style={{ color: '#3FA842', marginRight: 7, fontSize: 15, marginTop: 20 }}> {`${patient.idNumber}`}</i>
              <i style={{ color: '#060606', marginRight: 7, fontSize: 15, marginLeft: 20, marginTop: 20 }}>{t('personal_information.dob')}:</i>
              <i style={{ color: '#3FA842', marginRight: 7, fontSize: 15, marginTop: 20 }}> {`${patient.dateOfBirth}`}</i>
            </div>
            <div className='status-realtime-msg'>
              <span id='rt-status-msg'>{getStatusMessage()}</span>
              <span className='status-af-icon'>{getStatusAfIcon()}</span>
            </div>
            {(!isPatientNotResponded && !measurementComplete) &&
              <>
                <div className='data-section'>
                  <div className='measurement-section'>
                    <RealtimeMeasureBox rtMeasurements={rtMeasurements} patient={patient} setAlert={setAlert} alert={alert} configurations={configurations} />
                    <div className='ecg-wrap'>
                      <iframe className='ecg-section' src={'../../measurements_graph.html'} title="RealtimeGraph" id="realTimeMessageIframe">
                      </iframe>
                    </div>
                  </div>
                  <div>
                    <HrGraphBackgroundComponent color={'#000000'} linesNumber={5} >
                      <HrGraphXAxisComponent data={displayedSeconds} />
                    </HrGraphBackgroundComponent>
                  </div>
                </div>
              </>
            }
          </div>
        }
      
        {!showRealtime &&
          <Row style={{ display: 'flex', flexDirection: 'row', left: 0, justifyContent: 'flex-end', marginTop: '13%' }}>
            <Button style={{ border: '1px solid #002D66', backgroundColor: 'white', width: 200, borderRadius: 30, marginRight: 50, marginBottom: 20 }} onClick={() => handleClose()}>
              {t('modals.realtime.close')}
            </Button>
            <Button disabled={!realtimeData?.current?.length} style={{ border: '1px solid #002D66', backgroundColor: '#002D66', color: 'white', width: 200, borderRadius: 30, marginRight: 50, marginBottom: 20 }} onClick={() => handleSave()}>
              {t('modals.realtime.save')}
            </Button>
          </Row>
        }
      </div>

    </Modal>
  );
}
