
import { Card, CardBody, Form, Row, Col } from "reactstrap";
import { useState } from "react";
import CredentialsScreen from "./CredentialsScreen";
import axios from "axios";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie'
import { useDispatch } from "react-redux";
import { setMeasurementReminders, setUserToState } from "store/actions";
import moment from "moment";
import ActivateAccount from "./activate-account";

const Login = ({ setLoggedUser }) => {
  const isRegister = window.location.href.includes('register')
  const [failedMessage, setFailedMessage] = useState()
  const [emailActivateScreen, setEmailActivateScreen] = useState();
  const [displayCaptcha, setDisplayCaptcha] = useState(localStorage.getItem('displayCaptcha'));
  const dispatch = useDispatch()

  const handleSubmit = async (email, password, captchaValue) => {
    const username = email?.toLowerCase()
    isRegister ? handleRegister(username, password) : handleLogin(username, password, captchaValue)
  }

  const handleLogin = async (username, password, captchaValue) => {
       try { 
      const isPortal= true
      setDisplayCaptcha(false)
      const result = await axios.post(`${process.env.REACT_APP_API_URL}/app/auth/login`, { username, password, isPortal, captchaValue})
      const { role, doctor, patient, id: userId, token: jwtToken, isActivate } = result.data
      if (role === 'doctor' && !isActivate) return setEmailActivateScreen(username) //it may fail to the catch
      const appConfigs = (role === 'patient') ? await axios.get(`${process.env.REACT_APP_API_URL}/app/patient/app-configuration-jwt`,{ headers: {'Authorization': `Bearer ${jwtToken}`} }) : {}//its may just in patient case
      const patientOrDoctorData = doctor || patient || {}
      const user = { data: { ...patientOrDoctorData, userId, username,accessToken:result.data.accessTokens.accessToken, appConfigs:appConfigs?.data }, type: role || 'patient', jwtToken }

      delete user.data.peripheralHistory
      Cookies.set('user', JSON.stringify(user), { expires: 1 })
      localStorage.setItem('lastEntry', moment().utc().format('YYYY-MM-DD HH:mm'))
      localStorage.setItem('jwtToken', jwtToken)
      localStorage.removeItem('displayCaptcha')
      setLoggedUser(user) //add return of role on api-server-login
      dispatch(setUserToState(user))
      dispatch(setMeasurementReminders({[user.data.id]: user.data.measurementReminders}))
    }
    catch (err) {
      console.log('err', err)
      console.log('err data', err?.response?.data)
      const _displayCaptcha = err?.response?.data?.params?.displayCaptcha;
      console.log('displayCaptcha err', displayCaptcha)
      localStorage.setItem('displayCaptcha', _displayCaptcha)
      setDisplayCaptcha(_displayCaptcha)
      // if('role ===  doctor and user state === created')return setEmailActivateScreen(email address here) //may it fail to the catch
      setFailedMessage(err?.response?.data?.message || 'Incorrect email and/or password. Please try again')
    }
  }

  async function handleRegister(username, password) {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/app/user/register`, { username, password, role: 'doctor' })
      setEmailActivateScreen(username)
    } catch (err) {
      console.log('err', err)
      setFailedMessage(err?.response?.data?.message || 'Something went wrong. Please try again')
    }
  }

  return (
    <Col xl="auto" md="auto" sm="auto" xs="auto">
      <div style={{ width: '550px' }} >
        <div style={{ fontSize: '34px' }} className="text-white">{(isRegister || emailActivateScreen) ? <>Welcome!<p className="mb-1">Lets create your account</p></> : 'Login'}</div>
        {emailActivateScreen
          ?
          <ActivateAccount email={emailActivateScreen} setEmailActivateScreen={setEmailActivateScreen} />
          : <>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <CredentialsScreen isRegistesr={isRegister} onSubmit={handleSubmit} errMessage={failedMessage} displayCaptcha={displayCaptcha} />
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <Link to={`/auth/${isRegister ? 'login' : 'doctor/register'}`} className="text-link">
                  <small>{isRegister ? 'back to login' : 'New physicians registration>>'}</small>
                </Link>
              </Col>
            </Row>
          </>}
      </div>
    </Col>
  );
};

export default Login;




/* 
  const MFAauth = ({QRCode})=>
  <>
  {QRCode && <img src={QRCode} alt="qr-code" style={{display: 'block',marginLeft: 'auto', marginRight: 'auto'}} />}
  <FormGroup className="mb-3">
    <InputGroup className="input-group-alternative">
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <i className="ni ni-lock-circle-open" />
        </InputGroupText>
      </InputGroupAddon>
      <Input
        id="mfa-code"
        value={MFAcode}
        onChange ={e=>setMFAcode(e.target.value)}
        placeholder="Authentication Code"
        type="number"
      />
    </InputGroup>
  </FormGroup>
  <div className="text-center">
      <Button onClick={onMFASubmit} className="my-4" color="primary" type="button">
      Submit
    </Button>
  </div>
  </>
 */


/*   const onLogin = async () =>{
    const res = await axios.post(`${URL}/auth`,{email,password})
    if(res?.data?.success){
      setLoggedUser({email,token:res.data.accessToken})
    }
    else{
      alert(`Login Failed: ${res?.data?.message}`)
    }
  } */

/*
const UserPasswordAuth = () =>
<>
<FormGroup className="mb-3">
  <InputGroup className="input-group-alternative">
    <InputGroupAddon addonType="prepend">
      <InputGroupText>
        <i className="ni ni-email-83" />
      </InputGroupText>
    </InputGroupAddon>
    <Input
      value={email}
      onChange ={e=>setEmail(e.target.value)}
      placeholder="Email"
      type="email"
      autoComplete="new-email"
    />
  </InputGroup>
</FormGroup>
<FormGroup>
  <InputGroup className="input-group-alternative">
    <InputGroupAddon addonType="prepend">
      <InputGroupText>
        <i className="ni ni-lock-circle-open" />
      </InputGroupText>
    </InputGroupAddon>
    <Input
      value={password}
      onChange ={e=>setPassword(e.target.value)}
      placeholder="Password"
      type="password"
      autoComplete="new-password"
    />
  </InputGroup>
</FormGroup>
<div className="custom-control custom-control-alternative custom-checkbox">
  <input
    className="custom-control-input"
    id=" customCheckLogin"
    type="checkbox"
  />
  <label
    className="custom-control-label"
    htmlFor=" customCheckLogin"
  >
    <span className="text-muted">Remember me</span>
  </label>
</div>
<div className="text-center">
    <Button onClick={onLogin} className="my-4" color="primary" type="button">
    Sign in
  </Button>
</div>
</>
*/