import PatientDashboard from "pages/dashboard/Patient";
import DoctorDashboard from "pages/dashboard/Doctor";
import ForgotPassword from "pages/login/ForgotPassword";
import Login from "pages/login/Login";
import DoctorsPatient from "pages/doctor's patient/Home";
import MedicalInfo from "pages/profile/patient/Medical";
import PersonalInfo from "pages/profile/patient/PersonalInfo";
import Thresholds from "pages/profile/patient/Thresholds";
import Passwords from "pages/profile/patient/Passwords";
import Subscription from "pages/profile/patient/Subscription";
import MarketPlace from "pages/reports/MarketPlace";
import Archive from "pages/reports/archive";
import Physicians from "pages/physicians/Physicians";
import Viewers from "pages/viewers/Viewers";
import StaticReports from "pages/reports/StaticReports";
import PatientsRequest from "pages/doctor's patient/patients-requst";
import DoctorInfo from "pages/profile/DoctorInfo";
import EmailPreferences from "pages/profile/patient/emailPreferences";
import AccountDeletion from "pages/profile/patient/account-deletion";
import RealtimeReportComponent from "pages/reports/RealtimeReport";
import Patients from "components/admin/patients";
import Configurations from "components/admin/configurations";
import Countries from "components/admin/countries";
import Medications from "pages/profile/patient/medications";
import Fota from "components/admin/fota";
import VersionsComponent from "components/admin/versions.component";
import MeasuremetReminders from "pages/profile/patient/measurement-reminders";
import Watches from "components/admin/watches";
import MessagesComponent from "components/admin/messages-component";
// import RegisterPersonalInfo from "pages/login/register-personal-info";

const routes = [
  {
    path: "/dashboard",
    name: "Home",
    icon: "ni ni-tv-2 text-white",
    component: PatientDashboard,
    layout: "/patient",
  },
  // patient profile page sections
  {
    path: "/profile/personal-info",
    name: "My Profile",
    "section-name":"Personal Info",
    icon: "fas fa-sim-card text-white",
    component: PersonalInfo,
    layout: "/patient/profile",
  },
  {
    path: "/profile/medical-info",
    "section-name":"Medical Info",
    component: MedicalInfo,
    layout: "/patient/profile",
  },
  {
    path: "/profile/medications",
    "section-name":"Medications",
    component: Medications,
    layout: "/patient/profile",
  },
  {
    path: "/profile/measurements",
    "section-name":"Measurements",
    component: MeasuremetReminders,
    layout: "/patient/profile",
  },
  {
    path: "/profile/passwords",
    "section-name":"Passwords",
    component: Passwords,
    layout: "/patient/profile",
  },
  {
    path: "/profile/subscription",
    "section-name":"Subscription",
    component: Subscription,
    layout: "/patient/profile",
  },
  {
    path: "/profile/email-preferences",
    "section-name":"Email Preferences",
    component: EmailPreferences,
    layout: "/patient/profile",
  },
  {
    path: "/profile/account-deletion",
    "section-name":"Account Deletion",
    component: AccountDeletion,
    layout: "/patient/profile",
  },
  {
    path: "/profile/thresholds",
    "section-name":"Thresholds",
    component: Thresholds,
    layout: "/patient/profile",
  },
  // patient report sections
  {
    path: "/reports/marketplace",
    name: "Reports",
    "section-name":"Ask for report",
    icon: "fas fa-sim-card text-white",
    component: MarketPlace,
    layout: "/patient/reports",
  },
  {
    path: "/reports/archive",
    "section-name":"Reports archive",
    component: Archive,
    layout: "/patient/archive",
  },
  {
    path: "/static/reports/:type/:dates",
    "section-name":"static",
    component: StaticReports,
    layout: "/patient",
  },
  //
  {
    path: "/physicians",
    name: "Physicians",
    icon: "ni ni-watch-time text-white",
    component: Physicians,
    layout: "/patient",
  },
  // {
  //   path: "/viewers",
  //   name: "Viewers",
  //   icon: "fas fa-users text-white",
  //   component: Viewers,
  //   layout: "/patient",
  // },
  {
    path: "/dashboard",
    name: "Patient List",
    icon: "far fa-window-restore text-white",
    component: DoctorDashboard,
    layout: "/doctor",
  },

  {
    path: "/patient/:patientId/:subLocation",
    name: null,
    icon: null,
    component: DoctorsPatient,
    layout: "/doctor",
  },
  {
    path: "/static/reports/:type/:dates",
    "section-name":"static",
    component: StaticReports,
    layout: "/doctor",
  },
  {
    path: "/static/reports/realtime/",
    "section-name":"static",
    component: RealtimeReportComponent,
    layout: "/doctor",
  },

  // {
  //   path: "/dashboard/patient=:patientId/reports",
  //   name: null,
  //   icon: null,
  //   component:  DoctorDashboard,//()=><h1>Doctor1</h1>,
  //   layout: "/doctor",
  // },

  {
    path: "/profile",
    name: "My Profile",
    icon: "fas fa-user-lock text-white",
    component: DoctorInfo,//()=><h1>Doctor Profile</h1>,
    layout: "/doctor",
  },
  {
    path: "/profile/passwords",
    "section-name":"Passwords",
    component: Passwords,
    layout: "/doctor",
  },
  {
    path: "/profile/account-deletion",
    "section-name":"Account Deletion",
    component: AccountDeletion,
    layout: "/doctor",
  },
  {
    path: "/requests",
    name: "Patient Requests",
    icon: "far fa-window-restore text-white",
    component: PatientsRequest,
    // component: ()=> <h1>Doctor Requests</h1>,
    layout: "/doctor",
  },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-white",
    component: Login,
    layout: "/auth",
  }, 
  {
    path: "/doctor/register",
    name: "Register",
    icon: "ni ni-key-25 text-white",
    component: Login,
    layout: "/auth",
  }, 
  // {
  //   path: "/register/info",
  //   // name: "Register Info",
  //   icon: "ni ni-key-25 text-white",
  //   component: RegisterPersonalInfo,
  //   layout: "/doctor",
  // }, 
  {
    path: "/forgot password",
    name: "Forgot password",
    icon: "ni ni-key-25 text-white",
    component: ForgotPassword,
    layout: "/auth",
  }, 
  {
    path: "/patients",
    name: "Patients",
    icon: "ni ni-key-25 text-white",
    component: Patients,
    layout: "/admin",
  }, 
  {
    path: "/configurations",
    name: "Configurations",
    icon: "ni ni-key-25 text-white",
    component: Configurations,
    layout: "/admin",
  }, 
  {
    path: "/countries",
    name: "Countries",
    icon: "ni ni-key-25 text-white",
    component: Countries,
    layout: "/admin",
  }, 
  {
    path: "/fota",
    name: "Fota",
    icon: "ni ni-key-25 text-white",
    component: Fota,
    layout: "/admin",
  }, 
  {
    path: "/versions",
    name: "Versions",
    icon: "ni ni-key-25 text-white",
    component: VersionsComponent,
    layout: "/admin",
  }, 
  {
    path: "/watches",
    name: "watches",
    icon: "ni ni-key-25 text-white",
    component: Watches,
    layout: "/admin",
  },
  {
    path: "/messages",
    name: "messages",
    icon: "ni ni-key-25 text-white",
    component: MessagesComponent,
    layout: "/admin",
  },
];
export default routes;
