import { Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Collapse} from "reactstrap";
import { Link, useLocation } from "react-router-dom";

export default function SubNavbar({title,subPages=[],children}) {
    const location = useLocation()
    
    return (
        <Navbar id="sub-navbar" color="white" light expand="md" style={{paddingLeft:'38px'}} /*   className="border" */ >
            <NavbarToggler />
            <Collapse isOpen navbar>
                <Nav className="mr-auto w-100" navbar>
                    {subPages.map(page=> page===title?
                    <NavbarBrand id={page} key={page} style={{cursor:'pointer', color: '#093f7f'}} >{title}</NavbarBrand>
                    :
                    <NavItem style={{cursor:'pointer'}} key={page}>
                        <NavLink id={page} to={`${location.pathname}/reports`} tag={Link}>{page}</NavLink>
                    </NavItem>
                    )}
                    <NavItem className="ml-auto" >{children}</NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    )
}
